import { toPathObject } from '@/util/helpers';
import { getConstant } from '@/util/constantsHelpers';

export const getActiveFromArray = (array) => {
	if (!array || !Array.isArray(array)) return false;
	return array.find(e => e.to === null);
};

export const checkAndFilterDepends = ({ fields, data }) => {
	const inputsHidden = [];
	const _fields = [];
	
	fields.forEach(field => {
		if (field.depends?.length) {
			const responses = [];
			if (data) {
				field.depends.forEach(element => {
					let response = false;
					let fieldValue = data[element.field];
					if (element.values?.length) {
						if ('customField' in element) {
							fieldValue = data[element.customField.field];

							if (Array.isArray(fieldValue)) {
								fieldValue = getActiveFromArray(fieldValue);
							}
							if ('property' in element.customField) {
								fieldValue = toPathObject(fieldValue, element.customField.property);
							}

							if ('constants' in element.customField) {
								if (data[element.customField.field]) {
									const { service, field: _field,property:_property,keyToFind } = element.customField.constants;
									
									const payloadConstants={ field: { [_field]: data[element.customField.field] }, service,property:keyToFind};

									const _value = getConstant(payloadConstants);
									if (_value) {
										fieldValue = toPathObject(_value, _property);
									}

								}

							}
						}
					
						if (element.values.includes(fieldValue)) {
							response = true;
						}
					} else {
						if (fieldValue) {
							response = true;
						}
					}
					responses.push(response);
				});
			} else {
				responses.push(false);
			}
			if (responses.includes(false)) {
				inputsHidden.push(field);
			}
			_fields.push({ ...field, hidden: responses.includes(false) ? 'true' : 'false' });
		} else {
			_fields.push(field);
		}
	});

	return { fields:_fields, inputsHidden };
};
export default (rule, data, dataModel, field) => {
	const rules = {
		required,
		min: v => (v && v.toString().length >= data) || `Ingresar ${data} caracteres`,
		max: v => (v && v.toString().length <= data) || `Ingresar hasta ${data} caracteres`,
		exactly: v => (v && v.toString().length === data) || `Ingresar ${data} caracteres`,
		emailMatch: () => ('The email and password you entered don\'t match'),
		fullUrl: (value) => {
			const pattern = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

			return pattern.test(value) || 'URL no válida.';
		},
		array: (value) => (value && value.length > 0) || 'Completar',
		url: (value) => {
			const pattern = /^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}$/;

			return pattern.test(value) || 'URL no válida.';
		},
		email: (value) => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			return pattern.test(value) || 'Email no válido.';
		},
		number: v => (v && /^-?\d+$/.test(v)) || 'Ingresar un número válido',
		sameAs: v => (sameAs(v, data, dataModel)),
		// diferentAs: v => (diferentAs(v, data, dataModel)),
		biggerThan: v => (biggerThan(v, data, dataModel)),
		lessThan: v => (lessThan(v, data, dataModel)),
		lowerThan: v => (lowerThan(v, data, dataModel)),
		lowerOrEqualThan: v => (lowerOrEqualThan(v, data, dataModel)),
		apiValidation: () => (!field.error || data.msg)
	};
	return rules[rule] || null;
};

export const lessThan=(value, data, dataModel) => {
	const {fields,message}=data;
	let response = true;

	const notMatch=[];
	if (Array.isArray(fields) && dataModel) {
		fields.forEach(element => {
			if (Number(value) > Number(dataModel[element])) {
				notMatch.push(element);
				response = false;
			}
		});
	}
	const msg=message?message:`El valor debe ser menor que ${notMatch.toString()}`;

	return !value || (dataModel && response) || msg;
};

export const biggerThan=(value, data, dataModel) => {
	const {fields,message}=data;
	let response = true;

	const notMatch=[];
	if (Array.isArray(fields) && dataModel) {
		fields.forEach(element => {
			if (Number(value) <= Number(dataModel[element])) {
				notMatch.push(element);
				response = false;
			}
		});
	}
	const msg=message?message:`El valor debe ser mayor que ${notMatch.toString()}`;

	return !value || (dataModel && response) || msg;
};

export const lowerOrEqualThan=(value, data, dataModel) => {
	const {fields,message}=data;
	let response = true;

	const notMatch=[];
	if (Array.isArray(fields) && dataModel) {
		fields.forEach(element => {
			if (Number(value) > Number(dataModel[element])) {
				notMatch.push(element);
				response = false;
			}
		});
	}
	const msg=message?message:`El valor debe ser menor que ${notMatch.toString()}`;

	return (value && dataModel && response) || msg;
};


export const lowerThan=(value, data, dataModel) => {
	const {fields,message}=data;
	let response = true;

	const notMatch=[];
	if (Array.isArray(fields) && dataModel) {
		fields.forEach(element => {
			if (Number(value) >= Number(dataModel[element])) {
				notMatch.push(element);
				response = false;
			}
		});
	}
	const msg=message?message:`El valor debe ser menor que ${notMatch.toString()}`;

	return (value && dataModel && response) || msg;
};

export const sameAs = (value, data, dataModel) => {
	let response = true;
	const {data:fields}=data;
	if (Array.isArray(fields) && dataModel) {
		fields.forEach(element => {
			if (value !== dataModel[element]) {
				response = false;
			}
		});
	}

	return (value && dataModel && response) || 'No coincide el valor ingresado';
};

// export const diferentAs = (value, data, dataModel) => {
// 	let response = true;
// 	const {data:fields}=data;
// 	if (Array.isArray(fields?.params) && dataModel) {
// 		fields.params.forEach(element => {
// 			if (value === dataModel[element]) {
// 				response = false;
// 			}
// 		});
// 	}

// 	return response || fields.msg;
// };

export const required = value => {
	return !!value || 'Completar.';
};

export function counter (value) {
	return value.toString().length <= 20 || 'Ingresar hasta 20 caracteres';
}

export function email (value) {
	const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return pattern.test(value) || 'E-mail inválido.';
}

export function password (value) {
	return value.length > 5 || 'Ingresar al menos 5 caracteres';
}

export function validURL (url) {
	const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
	return reg.test(url) || 'URL inválida.';
}

export function isArray (arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]';
	}
	return Array.isArray(arg);
}

export function isExternal (path) {
	return /^(https?:|mailto:|tel:)/.test(path);
}

export function validLowerCase (str) {
	const reg = /^[a-z]+$/;
	return reg.test(str);
}

export function validUpperCase (str) {
	const reg = /^[A-Z]+$/;
	return reg.test(str);
}

export function validAlphabets (str) {
	const reg = /^[A-Za-z]+$/;
	return reg.test(str);
}

export function isString (str) {
	if (typeof str === 'string' || str instanceof String) {
		return true;
	}
	return false;
}

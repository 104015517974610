
import {stringToSlug} from '@/util/urlHelpers';
import {getVariableType, toPathObject} from '@/util/helpers';
import store from '@/store';

export const getConstant=({field,keyToReturn,service,property,filter})=> {
	let response = null;
	let _field = field;
	let _property=property;
	// Parse the decoded value as JSON if possible
	try {
		const constants=store.getters['app/constants'];
		if(getVariableType(_field)==='string'){
			const replacedString = _field.replace(/'/g, '"');
			_field= JSON.parse(replacedString);
		}
        
		if(_field && getVariableType(_field)==='object'){
			const isPropertyField=Object.keys(_field).find(e=>e==='property');
			if(isPropertyField && _field[isPropertyField]){
				_property=_field[isPropertyField];
			}
			const keyToFind=Object.keys(_field)[0];
			if(keyToFind && constants?.[service]){
				response=constants[service][keyToFind];

				if(_field[keyToFind] && _field[keyToFind]!=='null' && constants[service]?.[keyToFind]?.length){
					
					const find= constants[service][keyToFind].find(e=>{
						if(_property){
							return stringToSlug(_field[keyToFind])===stringToSlug(toPathObject(e, _property)); 
						}else{
							return stringToSlug(e.reference)===stringToSlug(_field[keyToFind]) || e.uuid===stringToSlug(_field[keyToFind]); 
						}
					});
					response=find;

					if(keyToReturn && find?.[keyToReturn]){
						response=find[keyToReturn];
					}
				}else{
					if(_property && filter){
						const filterSlug=stringToSlug(filter);
						if(filter)
							response = constants[service][keyToFind].filter(e=>{
								const object=toPathObject(e, _property);
								if(object?.length){
									return object.includes(filterSlug);
								}
								return filterSlug===stringToSlug(object);
							});
					}
				}
			}


		}
	} catch (error) {
		console.log(error);
		// If parsing fails, store the decoded value as a string
		response = field;
	}
	return response;
};
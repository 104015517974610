import store from '@/store';
import axios from 'axios';
import qs from 'qs';

const msgMethods = ['post', 'put', 'patch', 'delete'];
const noResponseUrl = ['login', 'validate-token'];
import router from '@/router';

const txtMsgMethods = {
	post: 'Agregado con éxito',
	put: 'Modificado con éxito',
	patch: 'Modificado con éxito',
	delete: 'Borrado con éxito'
};

axios.defaults.baseURL = process.env.VUE_APP_BASE_API; // url = base url + request url

// create an axios instance
const service = axios.create({
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 50000, // request timeout,
	headers:{
		'Access-Control-Allow-Origin':'*'
	},
	paramsSerializer: {
		serialize: (params) =>
			qs.stringify(params, { arrayFormat: 'brackets', encode: false })
	},
	env: {
		// The FormData class to be used to automatically serialize the payload into a FormData object
		FormData: window?.FormData || global?.FormData
	}
});

// request interceptor
service.interceptors.request.use(
	(config) => {

		return config;
	},
	(error) => {
		// do something with request error
		console.log(error); // for debug
		return Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(

	(response) => {
		const res = response.data;
		const successMSg = res?.message?.message || res?.message;
		if (successMSg) {
			store.commit('app/SET_ALERT_TXT', {
				timeout: 3700,
				type: 'success',
				txt: successMSg
			});
			store.commit('app/SET_ALERT', true);
		} else if (!noResponseUrl.includes(response.config.url) && msgMethods.includes(response?.config?.method)) {
			const method = response.config.headers._method || response?.config?.method;
			store.commit('app/SET_ALERT_TXT', {
				timeout: 3700,
				type: 'success',
				txt: txtMsgMethods[method.toLowerCase()]
			});
			store.commit('app/SET_ALERT', true);
		}

		return res;
	},
	async (error) => {
		const errorStatus = error?.response?.status;
		console.log('err' + error); // for debug
		console.log('status ' + errorStatus); // for debug
		let errorMsgTxt =  error?.response?.data?.error;

		let errorMSg = error?.response?.data?.error?.message;
		if (typeof errorMsgTxt === 'string') {
			errorMSg=`<div>${errorMsgTxt}</div>`;
		}

		const isObjectMsg = typeof errorMSg === 'object';
		if (isObjectMsg) {
			const itemsMsg = Object.values(errorMSg);
			errorMSg = itemsMsg.reduce(
				(previousValue, currentValue) => {
					if(Array.isArray(currentValue)){
						let txtArr='';
						currentValue.forEach(_el => {
							txtArr+=`<div>${_el}</div>`;
						});
						return previousValue + txtArr;
					}
					return previousValue + `<div>${currentValue}</div>`;
				},
				''
			);
		}

		if (!noResponseUrl.includes(error.config.url) || error.config.url === 'login') {
			if (errorMSg) {
				// if (error.response.status === 404) {
				// 	router.push({ path: '/' }).catch((e) => { console.log(e); });
				// }
				store.commit('app/SET_ALERT_TXT', {
					timeout: 3700,
					type: 'error',
					txt: errorMSg || 'Error'
				});
				store.commit('app/SET_ALERT', true);
			} else {
				store.commit('app/SET_ALERT_TXT', {
					timeout: 3700,
					type: 'error',
					txt: 'Error!'
				});
				store.commit('app/SET_ALERT', true);
			}
		}
		if (errorStatus === 401) {
			await store.dispatch('user/removeToken');
			router.push({ path: '/ingresar' }).catch((e) => { console.log(e); });
		}
		return Promise.reject(error);
	}
);

export default service;

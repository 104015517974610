<template>
  <VContainer fluid class="px-0 px-md-1 px-lg-2">
    <VRow>
      <VCol
        v-if="form.sourceActions && form.sourceActions.length"
        cols="12"
        class="d-flex justify-end align-center my-0 pt-0"
      >
        <custom-btn
          v-for="(action,key) in form.sourceActions"
          :key="key"
          :attributes="toAttributes(action.style)"
          :tooltip="action.title"
          :label="action.label"
          :icon="action.style?action.style.icon:false"
          :target="action.target"
          :href="action.href?generateStringFromVariables({ title: action.href, data:model }):null"
          :custom-class="action.style?action.style.class:''"
          :color="action.style?action.style.type:''"
          @handleClick="$emit(action.event&&action.event.action ?action.event.action:'click',action)"
        />
      </VCol>
      <VCol
        cols="12"
        class="px-0 pt-0"
      >
        <v-data-table
          dense
          :items-per-page="itemsPerPage"
          v-bind="$attrs"
          :headers="form.headers"
          :items="items"
          sort-by="id"
          class="elevation-0"
          locale="es-AR"
          no-data-text="No se encontraron resultados"
          no-results-text="No se encontraron resultados"
          :loading="loading"
          :item-key="itemKey"
          :item-class="itemRowBackground"
          loading-text="Cargando"
          :footer-props="{
            itemsPerPageText: 'Ítems por página',
            itemsPerPageOptions: [10, 12,50, 100, 150, 200, 250, -1],
            itemsPerPageAllText:'Todos'
          }"
          @click:row="handleRowClick"
        >
          <template
            v-for="(header_slot,key) in form.headers"
            #[`item.${header_slot.value}`]="{ item, index }"
          >
            <template v-if="header_slot.value==='actions'">
              <div :key="`${key}_actions_${index}`">
                <template v-for="(action,keyData) in header_slot.data">
                  <custom-btn
                    v-if="checkDepends(action,item)"
                    :key="`${key}_actions_${index}_${keyData}`"
                    :attributes="toAttributes(action.style)"
                    :tooltip="action.title"
                    :label="action.label"
                    :icon="action.style?action.style.icon:false"
                    :custom-class="action.style?action.style.class:''"
                    :color="action.style?action.style.type:''"
                    @handleClick="handleTableAction({item,header:header_slot,value:action})"
                  />
                </template>
              </div>
            </template>
            <template v-else>
              {{ formatter({value:header_slot.property?toPathObject(item,header_slot.property):item[header_slot.value] ,header:header_slot}) }}
            </template>
          </template>
        </v-data-table>
      </VCol>

      <VCol
        v-if="form.bottomActions && form.bottomActions.length"
        class="d-flex align-center pb-6 pt-6"
        cols="12"
      >
        <VRow
          :justify="form.bottomActions.length===1?'end':'space-between'"
          style="width:100%"
        >
          <VCol
            v-for="(action, index) in form.bottomActions"
            :key="`action-${index}`"
            cols="12"
            :sm="action.col ? action.col : 'auto'"
            class="pb-1"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              :custom-class="`text-none`"
              :color="action.style ? action.style.type : ''"
              :width="action.style ? action.style.width : ''"
              :loading="loading"
              :icon="action.style?action.style.icon:false"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>

    <confirm-modal
      :open.sync="confirmModal"
      :text="confirmModalText"
      :is-overlay="confirmModalLoading"
      @confirm="confirmModalAction"
    />
  </VContainer>
</template>

<script>
import { get } from 'vuex-pathify';
import { toAttributes } from '@/mappers/form';
import { toPathObject, generateStringFromVariables } from '@/util/helpers';
import formatter from '@/mixins/formatter';
export default {
	name: 'SimpleTable',
	filters: {

	},
	mixins: [formatter],
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		itemKey: {
			type: String,
			default: 'id'
		},

		form: {
			required: true,
			type: Object,
			default: function () {
				return { headers: [] };
			}
		},
		items: {
			required: true,
			type: Array,
			default: function () {
				return [];
			}
		},
		clickRow: {
			type: Boolean,
			default: false
		},
		itemsPerPage: {
			type: Number,
			default: 10
		},
    
		model: {
			type: Object,
			default: function () {
				return {};
			}
		}
	},
	data: () => ({
		selected:null,
		generateStringFromVariables,
		toAttributes,
		confirmModal: false,
		confirmModalText: 'Confirmar',
		confirmValue: null,
		confirmModalLoading: false,
		toPathObject
	}),
	computed:{
		...get('route', ['meta']),
		metaColor(){
			return this.meta?.color;
		}
	},
	watch: {
		clickRow () {
			this.selected=null;
		}
	},
	methods: {
		itemRowBackground (item) {
			if(this.clickRow &&item[this.itemKey]===this.selected?.[this.itemKey] ){
				return 'blue-grey lighten-4';
			}

			if(item.color){
				return item.color;
			}
			return '';
		},
		handleRowClick(item){
			this.$emit('clickRow',item);
			this.selected=item;
		},
		handleTableAction ({ item, header, value }) {
			if (value?.confirmation?.message) {
				const txt = generateStringFromVariables({ title: value.confirmation.message || '', data: item });
				this.confirmModal = true;
				this.confirmValue = { value, item };
				this.confirmModalText = txt;
			} else if (value.service === 'local') {
				const url = generateStringFromVariables({
					title: value.href,
					data: { person_uuid: this.model.uuid, ...item }
				});
				this.$router.push({ path: url });
			} else if (value.event?.action) {
				this.$emit(value.event.action, { item, header, value });

				this.$emit('formModalEvent', { event: value.event.action, action: { item, header, value } });
			}
		},
		async confirmModalAction () {
			if (this.confirmValue?.value?.event?.action) {
				this.$emit(this.confirmValue.value.event.action, this.confirmValue);
			}
			this.confirmModal = false;
			this.confirmModalLoading = false;
		},
		handleClick (action) {
			if (action.event?.action) {
				this.$emit(action.event.action, action);
			}
		},
		checkDepends(header,data){
     
			if(header?.depends?.length){
				let response=false;
				header.depends.forEach(element => {
					
					let fieldValue = data[element.field];
					if (element.values?.length) {
								
						if ('customField' in element) {
							fieldValue = data[element.customField.field];

							if ('key' in element.customField) {
								fieldValue = fieldValue?.[element.customField.key];
							}
							if ('property' in element.customField) {
								fieldValue = toPathObject(fieldValue, element.customField.property);
							}
						}
						if (element.values.includes(fieldValue)) {
							response=true;
						}
					}else{
						if(fieldValue){
							response=true;
						}
					}

				});
				return response;
			}
			return true;

		}
	}
};
</script>

<style>
</style>

import request from '@/util/request';
import store from '@/store';
import { getToken } from '@/util/auth';
import { getConstant } from '@/util/constantsHelpers';

export const genericRequest = async (query) => {
	try {
		if(String(query.checkTokenStatus)==='true'){
			const check= await store.dispatch('user/checkTokenStatus');
			if(!check){
				return {};
			}
		}
		const currentModule= await store.dispatch('user/getCurrentModule');

		const {url,queryParams}=getQueryParams(query.url);
		const headers=await headerMapper(query, currentModule);
		const requestData=requestMapper({ query, queryParams },currentModule);
		const response = await request({
			url,
			headers,
			...requestData
		});
		return response;
	} catch (error) {
		return error;
	}
};

const requestMapper = ({ query, queryParams }, currentModule) => {
	const method = query.method?.toLowerCase();
	const dataResponse = { patch: 'data', get: 'params', post: 'data', put: 'data', delete: 'data' };
	const key = dataResponse[method];
	const param = key || 'params';

	let baseURL = process.env.VUE_APP_BASE_API_RBAC;

	if(currentModule?.services?.length && query.service){
		const tokenService = currentModule.services.find((service) => { return service.name.toLowerCase() === query.service.toLowerCase(); });

		if(tokenService){
			baseURL = tokenService.url;
		}
	}

	return {
		[param]: {...query.params,...queryParams},
		baseURL,
		method
	};
};

const headerMapper = async (query, currentModule)=>{
	const method = query.method?.toLowerCase();
	const headers={...query?.headers?query.headers:{}};
	const token=await getTokenData(query, currentModule);

	if(token){
		headers['Authorization']=token;
	}
	headers._method = query.params?._method;
	if(!headers?.['Content-Type']){
		if(method==='post'){
			headers['Content-Type']='multipart/form-data';
		}
	
		if(method==='put' || method==='patch'){
			headers['Content-Type']='application/x-www-form-urlencoded';
		}
	
	}

	return headers;
};

const getTokenData=async (query, currentModule)=>{
	const token = await getToken();
	let authorization=null;
	let tokenType= 'Bearer';
	if (token?.access_token) {
		if(token.token_type){
			tokenType= token.token_type;
		}
		authorization=`${tokenType} ${token.access_token}`;
	}

	if(currentModule?.services?.length && query.service){
		const tokenService = currentModule.services.find((service) => { 
			const serviceName=service.name.toLowerCase();
			return serviceName!=='rbac' && serviceName === query.service.toLowerCase(); 
		});

		if(tokenService?.token){
			if(tokenService.token_type){
				tokenType= tokenService.token_type;
			}
			authorization = `${tokenType} ${tokenService.token}`;
		}
	}

	return authorization;
};

const getQueryParams=(str)=> {
	const queryParams = {};
	const [url,queryString ]= str.split('?'); 
	// Check if the string contains a '?' character
	if (queryString) {
		// Split the query string into individual parameter key-value pairs
		const pairs = queryString.split('&');

		for (const pair of pairs) {
			const [key, value] = pair.split('=');

			// Decode the key and value to handle special characters
			const decodedKey = decodeURIComponent(key);
			const decodedValue = decodeURIComponent(value);

			// Parse the decoded value as JSON if possible
			try {
				const replacedString = decodedValue.replace(/'/g, '"');
				let valueJson= JSON.parse(addSingleQuotesIfNeeded(replacedString));
				
				if(valueJson?.constants){
					const {field,service,keyToReturn}=valueJson.constants;
					valueJson= getConstant({field,service,keyToReturn});
				}
				queryParams[decodedKey] = valueJson;
			} catch (error) {
				console.log(error);
				// If parsing fails, store the decoded value as a string
				queryParams[decodedKey] = decodedValue;
			}
		}
	}

	return {url,queryParams};
};

function addSingleQuotesIfNeeded(str) {
	if ((!str.startsWith('{') && !str.endsWith('}')) && (!str.startsWith('"') || !str.endsWith('"'))) {
		return '"' + str + '"';
	} else {
		return str;
	}
}